import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import VideoModal from '../../components/VideoModal';
import styles from '../../styles/KeyActivitiesPage.module.css';
import { config } from "../../config/config";

const ValuePropositionsHelp= () => {
    const [ValuePropo, setValuePropo] = useState('');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch value propositions
        const fetchValuePropo = async () => {
            try {
                const response = await fetch(config.apiUrl+'/get_value_propositions', {
                    method: 'GET',
                    credentials: 'include',
                });
                if (response.ok) {
                    const data = await response.json();
                    setValuePropo(data.ValuePropo);
                } else {
                    throw new Error('Failed to load value propositions');
                }
            } catch (error) {
                console.error('Error fetching value propositions:', error);
            }
        };

        fetchValuePropo();
    }, []);

    const handleSave = async () => {
        try {
            const response = await fetch(config.apiUrl+'/save_value_propositions', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ ValuePropo }),
            });

            if (response.ok) {
                navigate('/channel-resources');
            } else {
                const data = await response.json();
                throw new Error(data.message || 'Unknown error saving value propositions');
            }
        } catch (error) {
            console.error('Error saving value propositions:', error);
        }
    };

    const handleGenerateCaseStudies = async () => {
        if (!ValuePropo.trim()) {
            alert('Please enter value propositions before generating case studies.');
            return;
        }

        try {
            const response = await fetch(config.apiUrl+'/generate_case_studies', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ type: 'value_propositions', content: ValuePropo }),
            });

            if (response.ok) {
                const data = await response.json();
                setValuePropo(data.caseStudies);
            } else {
                throw new Error('Failed to generate case studies');
            }
        } catch (error) {
            console.error('Error generating case studies:', error);
        }
    };

    return (

<>
<div className="keyActivitiesPage">
        <div className="container">
        <div className="card_black">
            <div className="row">
                <div className="col-md-4 left-section">
                <h2 className="text-left">Value Propositions</h2>
                    <p className="text-white description">Value Propositions describe the benefits your customers will receive from your products or services. Please review and edit the pre-filled suggestions below to best match your business idea.</p>
                    <div className="buttons-wrapper">
                        <button type="button" className="btn btn-info btn-custom" data-toggle="modal"  onClick={() => setShowModal(true)}>Watch Video</button>
                        <button type="button" className="btn btn-info btn-custom" onClick={() => navigate('/value-propositions-help')}>Value Propositions Help</button>
                    </div>
                </div>
                <div className="col-md-8 right-section">
                    <form id="ValuePropoForm" className="form-main">
                        <div className="form-group d-flex flex-column">
                            <textarea 
                             value={ValuePropo} 
                             onChange={(e) => setValuePropo(e.target.value)} 
                            className="form-control" id="ValuePropo" name="ValuePropo" rows="15" required></textarea>
                            <button type="submit" onClick={handleSave} className="btn btn-info btn-primary-custom">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    {showModal && <VideoModal videoUrl="https://www.youtube.com/embed/h1f94VOhWm4?autoplay=1" onClose={() => setShowModal(false)} title="Value Propositions Video" />}
    </div>
    </>
    );
};

export default ValuePropositionsHelp;
