import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import VideoModal from '../../components/VideoModal';
import styles from '../../styles/KeyActivitiesPage.module.css';
import { config } from "../../config/config";
const KeyActivitiesPageHelp = () => {
   

    return (

<>
<div class="helpPages">
<div class="container">
        <h1>Key Activities in Business Models: A Comprehensive Overview with Examples</h1>

        <p>Key activities are fundamental operations that a business must perform to deliver its value propositions, maintain customer relationships, and generate revenue. These activities are a crucial component of the Business Model Canvas, a strategic management tool that helps organizations visualize and innovate their business models. Understanding key activities is essential for both startups and established enterprises as they navigate their operational complexities and strive for sustainable growth.</p>

        <h2>Defining Key Activities</h2>
        <p>Key activities can be defined as the essential tasks and processes that a business must execute to fulfill its value proposition and achieve its objectives. These activities can be categorized into several types based on the nature of the business model:</p>
        
        <ul>
            <li><strong>Production Activities:</strong> Involve the creation and delivery of products or services, including manufacturing, assembly, and service provision.</li>
            <li><strong>Problem-Solving Activities:</strong> Focus on providing tailored solutions to customer challenges, often seen in consulting and service-oriented businesses.</li>
            <li><strong>Platform/Network Activities:</strong> Facilitate interactions between users, such as those in digital platforms like Airbnb or Uber, where the focus is on managing user engagement and transactions.</li>
        </ul>

        <h2>Types of Key Activities</h2>
        <h3>1. Production Activities</h3>
        <p>These activities are essential for businesses that manufacture goods or provide services. Examples include:</p>
        <ul>
            <li><strong>Manufacturing:</strong> In a car manufacturing company like Ford, key activities would include assembly line operations, quality control, and supply chain management.</li>
            <li><strong>Service Delivery:</strong> In a consulting firm like McKinsey & Company, key activities might involve client meetings, research, and report generation.</li>
        </ul>

        <h3>2. Problem-Solving Activities</h3>
        <p>These activities are crucial for businesses that offer customized solutions. For instance:</p>
        <ul>
            <li><strong>Consulting Firms:</strong> In a firm like Deloitte, key activities include client engagement, needs assessment, and solution development tailored to specific industry challenges.</li>
            <li><strong>Healthcare Providers:</strong> In a hospital, activities may involve patient diagnosis, treatment planning, and follow-up care, ensuring comprehensive patient management.</li>
        </ul>

        <h3>3. Platform/Network Activities</h3>
        <p>These are vital for businesses that operate on a platform model. Examples include:</p>
        <ul>
            <li><strong>User Acquisition:</strong> Platforms like Airbnb focus on acquiring hosts and guests through marketing and community engagement, ensuring a diverse range of listings.</li>
            <li><strong>Transaction Management:</strong> For Uber, this includes ensuring smooth transactions between riders and drivers, including payment processing and customer support.</li>
        </ul>

       

    </div>
    </div>
    </>
    );
};

export default KeyActivitiesPageHelp;
