import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import VideoModal from '../../components/VideoModal';
import styles from '../../styles/KeyActivitiesPage.module.css';
import { config } from "../../config/config";

const KeyPartnersHelp = () => {
    const [keyPartners, setKeyPartners] = useState('');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch key Partners
        const fetchKeyPartners = async () => {
            try {
                const response = await fetch(config.apiUrl+'/get_key_partners', {
                    method: 'GET',
                    credentials: 'include',
                });
                if (response.ok) {
                    const data = await response.json();
                    setKeyPartners(data.keyPartners);
                } else {
                    throw new Error('Failed to load Key partners');
                }
            } catch (error) {
                console.error('Error fetching Key partners:', error);
            }
        };

        fetchKeyPartners();
    }, []);

    const handleSave = async () => {
        try {
            const response = await fetch(config.apiUrl+'/save_key_partners', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ keyPartners }),
            });

            if (response.ok) {
                navigate('/revenue-streams');
            } else {
                const data = await response.json();
                throw new Error(data.message || 'Unknown error saving key partners');
            }
        } catch (error) {
            console.error('Error saving key partners:', error);
        }
    };

    const handleGenerateCaseStudies = async () => {
        if (!keyPartners.trim()) {
            alert('Please enter Key partners before generating case studies.');
            return;
        }

        try {
            const response = await fetch(config.apiUrl+'/generate_case_studies', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ type: 'key_partners', content: keyPartners }),
            });

            if (response.ok) {
                const data = await response.json();
                setKeyPartners(data.caseStudies);
            } else {
                throw new Error('Failed to generate case studies');
            }
        } catch (error) {
            console.error('Error generating case studies:', error);
        }
    };

    return (

<>
<div className="keyActivitiesPage">
        <div className="container">
        <div className="card_black">
            <div className="row">
                <div className="col-md-4 left-section">
                    <h2 className="text-left">Key Partners</h2>
                    <p class="text-white description">Key Partners describe the network of suppliers and partners that make the business model work. Please review and edit the pre-filled suggestions below to best match your business idea.</p>
                    <div className="buttons-wrapper">
                        <button type="button" className="btn btn-info btn-custom" data-toggle="modal"  onClick={() => setShowModal(true)}>Watch Video</button>
                        <button type="button" className="btn btn-info btn-custom" onClick={() => navigate('/key-partners-help')}>Key Partners Help</button>
                    </div>
                </div>
                <div className="col-md-8 right-section">
                    <form id="keyPartnersForm" className="form-main">
                        <div className="form-group d-flex flex-column">
                            <textarea 
                             value={keyPartners} 
                             onChange={(e) => setKeyPartners(e.target.value)} 
                            className="form-control" id="keyPartners" name="keyPartners" rows="15" required></textarea>
                            <button type="submit" onClick={handleSave} className="btn btn-info btn-primary-custom">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    {showModal && <VideoModal videoUrl="https://www.youtube.com/embed/FywQXXDFbRA?autoplay=1" onClose={() => setShowModal(false)} title="Key Partners Video" />}
    </div>
    
    </>
    );
};

export default KeyPartnersHelp;
