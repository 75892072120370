import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Alert, CircularProgress } from "@mui/material";
import { config } from "../config/config";
//import styles from '../styles/Cards.module.css';

const Cards = () => {
 
  return (
    <>
    <div className="cardsPage">
       <div class="container">
        <div class="row">
            <div class="card" id="card1">
                <h2>Card 1</h2>
                <p>Description for card 1</p>
                <p class="value">$10</p>
                <button class="activate-btn" onclick="checkPayment(1)">Activate</button>
            </div>
            <div class="card" id="card2">
                <h2>Card 2</h2>
                <p>Description for card 2</p>
                <p class="value">$15</p>
                <button class="activate-btn" onclick="checkPayment(2)">Activate</button>
            </div>
            <div class="card" id="card3">
                <h2>Card 3</h2>
                <p>Description for card 3</p>
                <p class="value">$20</p>
                <button class="activate-btn" onclick="checkPayment(3)">Activate</button>
            </div>
        </div>
        <div class="row">
            <div class="card" id="card4">
                <h2>Card 4</h2>
                <p>Description for card 4</p>
                <p class="value">$25</p>
                <button class="activate-btn" onclick="checkPayment(4)">Activate</button>
            </div>
            <div class="card" id="card5">
                <h2>Card 5</h2>
                <p>Description for card 5</p>
                <p class="value">$30</p>
                <button class="activate-btn" onclick="checkPayment(5)">Activate</button>
            </div>
            <div class="card" id="card6">
                <h2>Card 6</h2>
                <p>Description for card 6</p>
                <p class="value">$35</p>
                <button class="activate-btn" onclick="checkPayment(6)">Activate</button>
            </div>
        </div>
        <div class="row">
            <div class="card" id="card7">
                <h2>Card 7</h2>
                <p>Description for card 7</p>
                <p class="value">$40</p>
                <button class="activate-btn" onclick="checkPayment(7)">Activate</button>
            </div>
            <div class="card" id="card8">
                <h2>Card 8</h2>
                <p>Description for card 8</p>
                <p class="value">$45</p>
                <button class="activate-btn" onclick="checkPayment(8)">Activate</button>
            </div>
            <div class="card" id="card9">
                <h2>Card 9</h2>
                <p>Description for card 9</p>
                <p class="value">$50</p>
                <button class="activate-btn" onclick="checkPayment(9)">Activate</button>
            </div>
        </div>
        <div class="row">
            <div class="card" id="card10">
                <h2>Card 10</h2>
                <p>Description for card 10</p>
                <p class="value">$55</p>
                <button class="activate-btn" onclick="checkPayment(10)">Activate</button>
            </div>
            <div class="card" id="card11">
                <h2>Card 11</h2>
                <p>Description for card 11</p>
                <p class="value">$60</p>
                <button class="activate-btn" onclick="checkPayment(11)">Activate</button>
            </div>
            <div class="card" id="card12">
                <h2>Card 12</h2>
                <p>Description for card 12</p>
                <p class="value">$65</p>
                <button class="activate-btn" onclick="checkPayment(12)">Activate</button>
            </div>
        </div>
    </div>

    <div class="modal" id="paymentModal">
        <div class="modal-content">
            <span class="close-btn" onclick="closeModal()">&times;</span>
            <h2>Payment Required</h2>
            <p>Please pay to activate this card.</p>
            <button class="pay-btn" onclick="makePayment()">Pay Now</button>
        </div>
    </div>
    </div>

    </>
  );
};

export default Cards;
