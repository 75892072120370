import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import VideoModal from '../../components/VideoModal';
import styles from '../../styles/KeyActivitiesPage.module.css';
import { Alert } from '@mui/material';
import { config } from "../../config/config";
const CustomerRelationshipsHelp= () => {
    const [customerRelationships, setCustomerRelationships] = useState('');
    const [caseStudies, setCaseStudies] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    // Fetch customer relationships on component mount
    useEffect(() => {
        const fetchCustomerRelationships = async () => {
            try {
                const response = await fetch(config.apiUrl+'/get_customer_relationships', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    setCustomerRelationships(data.customerRelationships);
                } else {
                    throw new Error('Failed to load customer relationships');
                }
            } catch (error) {
                console.error('Error fetching customer relationships:', error);
                setError(error.message);
            }
        };

        fetchCustomerRelationships();
    }, []);

    // Handle form submission to save customer relationships
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(config.apiUrl+'/save_customer_relationships', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ customerRelationships }),
            });

            const data = await response.json();

            if (response.ok) {
                window.location.href = 'keyActivities.html';
            } else {
                throw new Error(data.message || 'Unknown error saving customer relationships');
            }
        } catch (error) {
            console.error('Error saving customer relationships:', error);
            setError(error.message);
        }
    };

    // Handle case studies generation
    const handleGenerateCaseStudies = async () => {
        if (!customerRelationships.trim()) {
            setError('Please enter customer relationships before generating case studies.');
            return;
        }

        try {
            const response = await fetch(config.apiUrl+'/generate_case_studies', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ type: 'customerRelationships', content: customerRelationships }),
            });

            const data = await response.json();

            if (response.status === 200) {
                setCaseStudies(data.caseStudies);
                setSuccess('Case studies generated successfully');
            } else {
                throw new Error('Error generating case studies');
            }
        } catch (error) {
            console.error('Error generating case studies:', error);
            setError(error.message);
        }
    };

    return (

<>
<div className="keyActivitiesPage">
        <div className="container">
        <div className="card_black">
            <div className="row">
                <div className="col-md-4 left-section">
                <h2 class="text-left">Customer Relationships</h2>
                    <p class="text-white description">What type of relationship are you establishing with each stakeholder customer segment to deliver your value?</p>
                    <div className="buttons-wrapper">
                        <button type="button" className="btn btn-info btn-custom" data-toggle="modal"  onClick={() => setShowModal(true)}>Watch Video</button>
                        <button type="button" className="btn btn-info btn-custom" onClick={() => navigate('/customer-relationships-help')}>Key Partners Help</button>
                    </div>
                </div>
                <div className="col-md-8 right-section">
                {error && <Alert severity="error" onClose={() => setError('')}>{error}</Alert>}
            {success && <Alert severity="success" onClose={() => setSuccess('')}>{success}</Alert>}
                    <form id="customerRelationshipsForm" className="form-main">
                        <div className="form-group d-flex flex-column">
                            <textarea 
                              value={customerRelationships}
                              onChange={(e) => setCustomerRelationships(e.target.value)}
                            className="form-control" id="customerRelationships" name="customerRelationships" rows="15" required></textarea>
                            <button type="submit" onClick={handleSubmit} className="btn btn-info btn-primary-custom">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    {showModal && <VideoModal videoUrl="https://www.youtube.com/embed/2zNxhB59FxA?autoplay=1" onClose={() => setShowModal(false)} title="Customer Relationships Video" />}
    </div>
    </>
    );
};

export default CustomerRelationshipsHelp;
