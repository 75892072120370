import React, { useState, useEffect,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// import Button from '../components/Button';
import VideoModal from '../components/VideoModal';
import styles from '../styles/KeyActivitiesPage.module.css';
import { config } from "../config/config";
import { Button, Alert, CircularProgress } from '@mui/material';
import Storage from '../utilities/Storage';

const KeyPartners = () => {
     const token = Storage.getItem('accesstoken');
    const [showModal, setShowModal] = useState(false);
    const [keyPartners, setKeyPartners] = useState('');
    const [caseStudies, setCaseStudies] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false); // Loader state
    const navigate = useNavigate(); // For navigation

    // Fetch key partners on component mount
    useEffect(() => {
        const fetchKeyPartners = async () => {
            setLoading(true);
            try {
                const response = await fetch(config.apiUrl+'/user/get_key_partners', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'locale': 'en',
                        'tokentype': 'email',
                        'accesstoken': token,
                       
                      },
                });

                if (response.ok) {
                    const data = await response.json();
                    setKeyPartners(data.keyPartners);
                } else {
                    throw new Error('Failed to load key partners');
                }
            } catch (error) {
                console.error('Error fetching key partners:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchKeyPartners();
    }, []);

    // Handle form submission to save key partners
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(config.apiUrl+'/user/save_key_partners', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'locale': 'en',
                    'tokentype': 'email',
                    'accesstoken': token,
                   
                  },
                body: JSON.stringify({ keyPartners: keyPartners }),
            });

            if (response.ok) {
                navigate('/revenue-streams'); // Navigate to revenue streams page
            } else {
                const data = await response.json();
                throw new Error(data.message || 'Unknown error saving key partners');
            }
        } catch (error) {
            console.error('Error saving key partners:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Handle case studies generation
    const handleGenerateCaseStudies = async () => {
        if (!keyPartners.trim()) {
            setError('Please enter Key Partners before generating case studies.');
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(config.apiUrl+'/user/generate_case_studies', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'locale': 'en',
                    'tokentype': 'email',
                    'accesstoken': token,
                   
                  },
                body: JSON.stringify({ type: 'key_partners', content: keyPartners }),
            });

            if (response.ok) {
                const data = await response.json();
                setCaseStudies(data.caseStudies);
                setSuccess('Case studies generated successfully');
            } else {
                throw new Error('Failed to generate case studies');
            }
        } catch (error) {
            console.error('Error generating case studies:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (

<>
{loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                }}>
                    <CircularProgress />
                </div>
            )}
<div className="keyActivitiesPage">
        <div className="container">
        <div className="card_black">
            <div className="row">
                <div className="col-md-4 left-section">
                    <h2 className="text-left">Key Partners</h2>
                    <p class="text-white description">Key Partners describe the network of suppliers and partners that make the business model work. Please review and edit the pre-filled suggestions below to best match your business idea.</p>
                    <div className="buttons-wrapper">
                        <button type="button" className="btn btn-info btn-custom" data-toggle="modal"  onClick={() => setShowModal(true)}>Watch Video</button>
                        <button type="button" className="btn btn-info btn-custom" onClick={() => navigate('/key-partners-help')}>Key Partners Help</button>
                    </div>
                </div>
                <div className="col-md-8 right-section">
                {error && <Alert severity="error" onClose={() => setError('')}>{error}</Alert>}
            {success && <Alert severity="success" onClose={() => setSuccess('')}>{success}</Alert>}

          
                    <form id="keyPartnersForm" className="form-main">
                        <div className="form-group d-flex flex-column">
                            <textarea 
                             value={keyPartners} 
                             onChange={(e) => setKeyPartners(e.target.value)} 
                            className="form-control" id="keyPartners" name="keyPartners" rows="15" required></textarea>
                             {/* <button type="submit" onClick={handleSave} className="btn btn-info btn-primary-custom">Save</button> */}
                             <Button onClick={handleSubmit} variant="contained" color="primary" type="submit" className="btn btn-info btn-primary-custom">
                            Save
                        </Button>
                        </div>
                    </form>
                   
                </div>
            </div>
        </div>
    </div>

    {showModal && <VideoModal videoUrl="https://www.youtube.com/embed/FywQXXDFbRA?autoplay=1" onClose={() => setShowModal(false)} title="Key Partners Video" />}
    </div>
    
    </>
    );
};

export default KeyPartners;
