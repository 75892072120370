
import Error from "./pages/page404";
import KeyActivitiesPage from './pages/KeyActivitiesPage';
import KeyResourcesPage from './pages/KeyResourcesPage';
import ChannelResources from './pages/ChannelResources';
import BusinessIdea from './pages/BusinessIdea';
import KeyPartners from './pages/KeyPartners';
import RevenueStreams from './pages/RevenueStreams';
import CustomerRelationships from './pages/CustomerRelationships';
import CustomerSegments from './pages/CustomerSegments';
import CostStructure from './pages/CostStructure';
import ValuePropositions from './pages/ValuePropositions';
import Register from './pages/Register';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Cards from './pages/Cards';
import CustomerSegmentStudyMaterial from './pages/CustomerSegmentStudyMaterial';
import Debugging from './pages/Debugging';

// Help Pages
import ChannelResourcesHelp from './pages/help/ChannelResourcesHelp';
import CostStructureHelp from './pages/help/CostStructureHelp';
import CustomerRelationshipsHelp from './pages/help/CustomerRelationshipsHelp';
import CustomerSegmentsHelp from './pages/help/CustomerSegmentsHelp';
import KeyActivitiesPageHelp from './pages/help/KeyActivitiesPageHelp';
import KeyPartnersHelp from './pages/help/KeyPartnersHelp';
import KeyResourcesPageHelp from './pages/help/KeyResourcesPageHelp';
import RevenueStreamsHelp from './pages/help/RevenueStreamsHelp';
import ValuePropositionsHelp from './pages/help/ValuePropositionsHelp';


export const allRoutes = [
  {
    path: "/",
    component: Login,
    exact: true,
  },
  {
    path: "/login",
    component: Login,
    exact: true,
  },
  {
    path: "/register",
    component: Register,
    exact: true,
  },
 
  {
    path: "/key-activities",
    component: KeyActivitiesPage,
    exact: true,
  },
  {
    path: "/channel-resources",
    component: ChannelResources,
    exact: true,
  },
  {
    path: "/key-resources",
    component: KeyResourcesPage,
    exact: true,
  },
  {
    path: "/business-idea",
    component: BusinessIdea,
    exact: true,
  },
  {
    path: "/key-partners",
    component: KeyPartners,
    exact: true,
  },
  {
    path: "/revenue-streams",
    component: RevenueStreams,
    exact: true,
  },
  {
    path: "/customer-relationships",
    component: CustomerRelationships,
    exact: true,
  },
  {
    path: "/customer-segments",
    component: CustomerSegments,
    exact: true,
  },
  {
    path: "/cost-structure",
    component: CostStructure,
    exact: true,
  },
  {
    path: "/value-propositions",
    component: ValuePropositions,
    exact: true,
  },
  {
    path: "/dashboard",
    component: Dashboard,
    exact: true,
  },
  {
    path: "/cards",
    component: Cards,
    exact: true,
  },
  {
    path: "/customer-segment-study-material",
    component: CustomerSegmentStudyMaterial,
    exact: true,
  },
  {
    path: "/debugging",
    component: Debugging,
    exact: true,
  },
  // Help Pages 
  {
    path: "/channel-resources-help",
    component: ChannelResourcesHelp,
    exact: true,
  },
  {
    path: "/cost-structure-help",
    component: CostStructureHelp,
    exact: true,
  },
  {
    path: "/customer-relationships-help",
    component: CustomerRelationshipsHelp,
    exact: true,
  },
  {
    path: "/customer-segments-help",
    component: CustomerSegmentsHelp,
    exact: true,
  },
  {
    path: "/key-activities-help",
    component: KeyPartnersHelp,
    exact: true,
  },
  {
    path: "/key-partners-help",
    component: KeyActivitiesPageHelp,
    exact: true,
  },
  {
    path: "/key-resources-help",
    component: KeyResourcesPageHelp,
    exact: true,
  },
  {
    path: "/revenue-streams-help",
    component: RevenueStreamsHelp,
    exact: true,
  },
  {
    path: "/value-propositions-help",
    component: ValuePropositionsHelp,
    exact: true,
  },
  
];
