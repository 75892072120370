import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { config } from "../config/config";
// import Storage from '../utilities/Storage';
import Storage from '../utilities/Storage';

import {
    Button,
    Alert,
    CircularProgress,
  } from '@mui/material';
const Register= () => {
    // const { login, token } = useContext(AuthContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
      setError('');
      setSuccess('');
  
      if (password !== confirmPassword) {
        setError('Passwords do not match');
        setLoading(false);
        return;
      }
  
      try {
        const payload = {
          req_param: {
            email_phone: email,
            password: password,
            mobile: mobile,
          }
        };
        const response = await fetch(config.apiUrl+'/user/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'locale': 'en',
            // 'tokentype': 'email',
            // 'accesstoken': '••••••',
            // 'user-id': '83ad4ef9-1a31-4731-bdd7-fb930a06532e',
          },
          body: JSON.stringify(payload),
        });
  
        const data = await response.json();
  
        if (response.status === 200) {
          console.log(data);
          // login(data.res_data.data.accesstoken); //set the token
          Storage.setItem("accesstoken", data.res_data.data.accesstoken)
          setSuccess('Registration successful');

          if (data.redirect == 'businessIdea.html') {
            navigate('/business-idea');
          } else {
            // navigate('/dashboard');
            navigate('/business-idea');
          }

          // setTimeout(() => {
          //   window.location.href = 'login.html';
          // }, 1000);
          
        } else {
          setError(data.error.message);
          // throw new Error(data.error);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    return (

<>
<div className="keyActivitiesPage">
<div  className="container">
        <div  className="card_black">
            <div  className="row justify-content-center">
                <div  className="col-md-6">
                    <h2  className="text-center mt-5">Register</h2>
                    {error && <Alert severity="error">{error}</Alert>}
                    {success && <Alert severity="success">{success}</Alert>}

                    <form id="registerForm"  className="mt-4 form-main" onSubmit={handleSubmit}>
                        <div  className="form-group d-flex flex-column">
                            <label for="email">Email:</label>
                            <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"  className="form-control" id="email" name="email" required/>
                        </div>
                        <div  className="form-group d-flex flex-column">
                            <label for="mobile">Mobile:</label>
                            <input
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            type="tel"  className="form-control" id="mobile" name="mobile" required/>
                        </div>
                        <div  className="form-group d-flex flex-column">
                            <label for="password">Password:</label>
                            <input 
                             value={password}
                             onChange={(e) => setPassword(e.target.value)}
                            type="password"  className="form-control" id="password" name="password" required/>
                        </div>
                        <div  className="form-group d-flex flex-column">
                            <label for="confirmPassword">Confirm Password:</label>
                            <input
                             value={confirmPassword}
                             onChange={(e) => setConfirmPassword(e.target.value)}
                            type="password"  className="form-control" id="confirmPassword" name="confirmPassword" required/>
                        </div>
                        {/* <button type="submit"  className="btn btn-primary mt-4 ml-auto">Register</button> */}
                        <Button
                         className="btn btn-custom mt-4 ml-auto"
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          style={{ marginTop: '0px' }}
        >
          {loading ? <CircularProgress size={24} /> : 'Register'}
        </Button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </div>
    </>
    );
};

export default Register;
