import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  Navigate
} from "react-router-dom";
import { allRoutes } from "./routes";
import error from "./pages/page404";

const REACT_DOMAIN_NAME = config.REACT_DOMAIN_NAME?config.REACT_DOMAIN_NAME:''
const CLARITY_PROJECT_ID = config.CLARITY_PROJECT_ID?config.CLARITY_PROJECT_ID:''


// ////////////
import { AuthProvider } from './context/AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import * as Sentry from '@sentry/react';
import { config } from "./config/config";
import useClarity from './utilities/useClarity';


Sentry.init({
  dsn: config.REACT_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", REACT_DOMAIN_NAME],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  useClarity(CLARITY_PROJECT_ID);
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const getComponent = (component: React.ComponentType<any>) => {
    return React.createElement(component, {});
  };
 

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <ErrorBoundary>
            <AuthProvider>
      <Routes>
        {/* start English routes and default routes */}
        <Route path={"/*"} element={getComponent(error)} />
        <Route
          path={"/"}
          element={
            <Navigate replace to={"/en/login"} />
          }
        />
        <Route
          path={"/en"}
          element={
            <Navigate replace to={"/en/login"} />
          }
        />
        {allRoutes.map((route, index) => (
          <Route
            path={route.path}
            element={
              <Navigate replace to={"/en" + route.path } />
            }
          />
        ))}
        {allRoutes.map((route, index) => (
          <Route
            path={"/en" + route.path}
            element={getComponent(route.component)}
          />
        ))}
        {allRoutes.map((route, index) => (
          <Route
            path={"/en" + route.path + "/*"}
            element={getComponent(route.component)}
          />
        ))}
        {/* end English routes and default routes */}
        

        {/* start Hindi routes */}
        <Route
          path={"/hi"}
          element={
            <Navigate replace to={"/hi/login"} />
          }
        />
        {allRoutes.map((route, index) => (
          <Route
            path={"/hi" + route.path}
            element={getComponent(route.component)}
          />
        ))}
        {allRoutes.map((route, index) => (
          <Route
            path={"/hi" + route.path + "/*"}
            element={getComponent(route.component)}
          />
        ))}
        {/* end Hindi routes */}


        {/* start espanis routes */}
        <Route
          path={"/es"}
          element={
            <Navigate replace to={"/es/login"} />
          }
        />
        {allRoutes.map((route, index) => (
          <Route
            path={"/es" + route.path}
            element={getComponent(route.component)}
          />
        ))}
        {allRoutes.map((route, index) => (
          <Route
            path={"/es" + route.path + "/*"}
            element={getComponent(route.component)}
          />
        ))}
        {/* end espanis routes */}
      </Routes>
      </AuthProvider>
        </ErrorBoundary>
  );
}
export default App;
