import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import VideoModal from '../../components/VideoModal';
import styles from '../../styles/KeyActivitiesPage.module.css';
import { config } from "../../config/config";

const RevenueStreamsHelp = () => {
    const [RevenueStr, setRevenueStr] = useState('');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch revenue stream
        const fetchRevenueStr = async () => {
            try {
                const response = await fetch(config.apiUrl+'/get_revenue_streams', {
                    method: 'GET',
                    credentials: 'include',
                });
                if (response.ok) {
                    const data = await response.json();
                    setRevenueStr(data.RevenueStr);
                } else {
                    throw new Error('Failed to load revenue stream');
                }
            } catch (error) {
                console.error('Error fetching revenue stream:', error);
            }
        };

        fetchRevenueStr();
    }, []);

    const handleSave = async () => {
        try {
            const response = await fetch(config.apiUrl+'/save_revenue_streams', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ RevenueStr }),
            });

            if (response.ok) {
                navigate('/cost-structure');
            } else {
                const data = await response.json();
                throw new Error(data.message || 'Unknown error saving revenue stream');
            }
        } catch (error) {
            console.error('Error saving revenue stream:', error);
        }
    };

    const handleGenerateCaseStudies = async () => {
        if (!RevenueStr.trim()) {
            alert('Please enter revenue stream before generating case studies.');
            return;
        }

        try {
            const response = await fetch(config.apiUrl+'/generate_case_studies', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ type: 'revenue_streams', content: RevenueStr }),
            });

            if (response.ok) {
                const data = await response.json();
                setRevenueStr(data.caseStudies);
            } else {
                throw new Error('Failed to generate case studies');
            }
        } catch (error) {
            console.error('Error generating case studies:', error);
        }
    };

    return (

<>
<div className="keyActivitiesPage">
        <div className="container">
        <div className="card_black">
            <div className="row">
                <div className="col-md-4 left-section">
                <h2 className="text-left">Revenue Streams</h2>
                    <p className="text-white description">Revenue Streams describe how your business will make money. Please review and edit the pre-filled suggestions below to best match your business idea.</p>
                    <div className="buttons-wrapper">
                        <button type="button" className="btn btn-info btn-custom" data-toggle="modal"  onClick={() => setShowModal(true)}>Watch Video</button>
                        <button type="button" className="btn btn-info btn-custom" onClick={() => navigate('/revenue-streams-help')}>Key Partners Help</button>
                    </div>
                </div>
                <div className="col-md-8 right-section">
                    <form id="RevenueStrForm" className="form-main">
                        <div className="form-group d-flex flex-column">
                            <textarea 
                             value={RevenueStr} 
                             onChange={(e) => setRevenueStr(e.target.value)} 
                            className="form-control" id="RevenueStr" name="RevenueStr" rows="15" required></textarea>
                            <button type="submit" onClick={handleSave} className="btn btn-info btn-primary-custom">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    {showModal && <VideoModal videoUrl="https://www.youtube.com/embed/mjeRUMgCKfU?autoplay=1" onClose={() => setShowModal(false)} title="Revenue Streams Video" />}
    </div>
    </>
    );
};

export default RevenueStreamsHelp;
