import React, { useState, useEffect,useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Alert, CircularProgress } from "@mui/material";
import { config } from "../config/config";
//import styles from '../styles/Cards.module.css';

const CustomerSegmentStudyMaterial = () => {
  return (
    <>
      <div className="customerSegmentMaterial">
        <div class="container">
          <h1>Customer Segmentation Powerhouse: Craft Targeted Strategies</h1>
          <p>
            Unleash the power of customer insights with effective segmentation!
            Group your audience based on shared characteristics to design
            laser-focused marketing campaigns, develop products that resonate,
            and ultimately drive business growth.
          </p>

          <h2>Essential Resources for Customer Segmentation Success:</h2>

          <div class="resources">
            <a href="https://www.forbes.com/advisor/business/customer-segmentation/">
              The Ultimate Guide to Customer Segmentation (Forbes Advisor)
            </a>
            <a href="https://blog.hubspot.com/service/customer-segmentation">
              Customer Segmentation: How to Segment Users & Clients Effectively
              (HubSpot)
            </a>
            <a href="https://www.coursera.org/courses?query=customer+segmentation">
              Customer Segmentation: Definition, Examples + How to Do It
              (Coursera)
            </a>
            <a href="https://www.smartinsights.com/customer-relationship-management/customer-segmentation/customer-segmentation-models.asp">
              Customer Segmentation Models: A Practical Guide (Smart Insights)
            </a>
            <a href="https://www.investopedia.com/terms/c/customersegmentation.asp">
              Customer Segmentation (Investopedia)
            </a>
            <a href="https://www.youtube.com/results?search_query=customer+segmentation+business+plan">
              Free Customer Segmentation Courses & Videos on YouTube
            </a>
          </div>

          <h2>Dive Deeper: High-Rated Courses and Tutorials:</h2>

          <div class="resources">
            <a href="https://www.udemy.com/course/customer-segmentation-the-ultimate-guide/">
              Customer Segmentation: The Ultimate Guide (Udemy)
            </a>
            <a href="https://www.skillshare.com/classes/Customer-Segmentation-for-Marketing-Success/1228546453/sessions/1417617125">
              Customer Segmentation for Marketing Success (Skillshare)
            </a>
            <a href="https://www.linkedin.com/learning/customer-segmentation-the-essentials">
              Customer Segmentation: The Essentials (LinkedIn Learning)
            </a>
          </div>

          <h2>Explore Industry-Specific Resources:</h2>

          <div class="resources">
            <a href="https://www.mckinsey.com/business-functions/marketing-and-sales/our-insights/customer-segmentation-a-powerful-tool-for">
            Customer segmentation a powerful tool for..
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerSegmentStudyMaterial;
