import React from 'react';
import PropTypes from 'prop-types';

const VideoModal = ({ videoUrl, onClose,title }) => (
    <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{title}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={onClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <iframe 
                        className="video-stream" 
                        width="100%" 
                        height="400" 
                        src={videoUrl} 
                        title={title}
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen
                    />
                </div>
            </div>
        </div>
    </div>
);

VideoModal.propTypes = {
    videoUrl: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default VideoModal;
