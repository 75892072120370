import React, { useState, useEffect,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// import Button from '../components/Button';
import VideoModal from '../components/VideoModal';
//import DynamicPage from '../components/DynamicPage';
import styles from '../styles/KeyActivitiesPage.module.css';
import { config } from "../config/config";
import { Button, Alert, CircularProgress } from '@mui/material';
import Storage from '../utilities/Storage';

const KeyActivitiesPage = () => {
     const token = Storage.getItem('accesstoken');
    const [showModal, setShowModal] = useState(false);
    const [keyActivities, setKeyActivities] = useState('');
    const [caseStudies, setCaseStudies] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false); // State for managing loader
    const navigate = useNavigate(); // For navigation

    // Fetch key activities on component mount
    useEffect(() => {
        const fetchKeyActivities = async () => {
            setLoading(true);
            try {
                const response = await fetch(config.apiUrl+'/user/get_key_activities', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'locale': 'en',
                        'tokentype': 'email',
                        'accesstoken': token,
                       
                      },
                });

                if (response.ok) {
                    const data = await response.json();
                    setKeyActivities(data.keyActivities);
                } else {
                    throw new Error('Failed to load key activities');
                }
            } catch (error) {
                console.error('Error fetching key activities:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchKeyActivities();
    }, []);

    // Handle form submission to save key activities
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(config.apiUrl+'/user/save_key_activities', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'locale': 'en',
                    'tokentype': 'email',
                    'accesstoken': token,
                   
                  },
                body: JSON.stringify({ keyActivities: keyActivities }),
            });

            if (response.ok) {
                navigate('/key-resources'); // Use navigate to change the route
            } else {
                const data = await response.json();
                throw new Error(data.message || 'Unknown error saving key activities');
            }
        } catch (error) {
            console.error('Error saving key activities:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Handle case studies generation
    const handleGenerateCaseStudies = async () => {
        if (!keyActivities.trim()) {
            setError('Please enter Key Activities before generating case studies.');
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(config.apiUrl+'/user/generate_case_studies', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'locale': 'en',
                    'tokentype': 'email',
                    'accesstoken': token,
                   
                  },
                body: JSON.stringify({ type: 'key_activities', content: keyActivities }),
            });

            if (response.status === 200) {
                const data = await response.json();
                setCaseStudies(data.caseStudies);
                setSuccess('Case studies generated successfully');
            } else {
                throw new Error('Failed to generate case studies');
            }
        } catch (error) {
            console.error('Error generating case studies:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (

<>
{loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                }}>
                    <CircularProgress />
                </div>
            )}
<div className="keyActivitiesPage">
        <div className="container">
        <div className="card_black">
            <div className="row">
                <div className="col-md-4 left-section">
                    <h2 className="text-left">Key Activities</h2>
                    <p className="text-white description">Key Activities describe the most important actions your business must take to operate successfully. Please review and edit the pre-filled suggestions below to best match your business idea.</p>
                    <div className="buttons-wrapper">
                        <button type="button" className="btn btn-info btn-custom" data-toggle="modal"  onClick={() => setShowModal(true)}>Watch Video</button>
                        <button type="button" className="btn btn-info btn-custom" onClick={() => navigate('/key-activities-help')}>Key Activities Help</button>
                    </div>
                </div>
                <div className="col-md-8 right-section">
                {error && <Alert severity="error" onClose={() => setError('')}>{error}</Alert>}
            {success && <Alert severity="success" onClose={() => setSuccess('')}>{success}</Alert>}

           
                    <form id="keyActivitiesForm" className="form-main">
                        <div className="form-group d-flex flex-column">
                            <textarea 
                             value={keyActivities} 
                             onChange={(e) => setKeyActivities(e.target.value)} 
                            className="form-control" id="keyActivities" name="keyActivities" rows="15" required></textarea>
                            {/* <button type="submit" onClick={handleSave} className="btn btn-info btn-primary-custom">Save</button> */}
                            <Button onClick={handleSubmit} variant="contained" color="primary" type="submit" className="btn btn-info btn-primary-custom">
                            Save
                        </Button>
                        </div>
                    </form>
                  
                </div>
            </div>
        </div>
    </div>

    {showModal && <VideoModal videoUrl="https://www.youtube.com/embed/aUm3qL0cwe4?autoplay=1" onClose={() => setShowModal(false)} />}
    </div>
    </>
    );
};

export default KeyActivitiesPage;
