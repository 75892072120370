import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import VideoModal from '../../components/VideoModal';
import styles from '../../styles/KeyActivitiesPage.module.css';
import { Alert } from '@mui/material';
import { config } from "../../config/config";
const CostStructureHelp= () => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const [costStructure, setCostStructure] = useState('');
    const [caseStudies, setCaseStudies] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // Fetch cost structure on component mount
    useEffect(() => {
        const fetchCostStructure = async () => {
            try {
                const response = await fetch(config.apiUrl+'/get_cost_structure', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    setCostStructure(data.costStructure);
                } else {
                    throw new Error('Failed to load cost structure');
                }
            } catch (error) {
                console.error('Error fetching cost structure:', error);
                setError(error.message);
            }
        };

        fetchCostStructure();
    }, []);

    // Handle form submission to save cost structure
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(config.apiUrl+'/save_cost_structure', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ costStructure }),
            });

            if (response.status === 200) {
                window.location.href = 'dashboard.html';
            } else {
                const data = await response.json();
                throw new Error(data.message || 'Unknown error saving cost structure');
            }
        } catch (error) {
            console.error('Error saving cost structure:', error);
            setError(error.message);
        }
    };

    // Handle case studies generation
    const handleGenerateCaseStudies = async () => {
        if (!costStructure.trim()) {
            setError('Please enter Cost Structure before generating case studies.');
            return;
        }

        try {
            const response = await fetch(config.apiUrl+'/generate_case_studies', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ type: 'cost_structure', content: costStructure }),
            });

            if (response.status === 200) {
                const data = await response.json();
                setCaseStudies(data.caseStudies);
                setSuccess('Case studies generated successfully');
            } else {
                throw new Error('Failed to generate case studies');
            }
        } catch (error) {
            console.error('Error generating case studies:', error);
            setError(error.message);
        }
    };

    return (

<>
<div className="keyActivitiesPage">
        <div className="container">
        <div className="card_black">
            <div className="row">
                <div className="col-md-4 left-section">
                <h2 className="text-left">Cost Structure</h2>
                    <p className="text-white description">Cost Structure describes all costs incurred to operate a business model. Please review and edit the pre-filled suggestions below to best match your business idea.</p>
                    <div className="buttons-wrapper">
                        <button type="button" className="btn btn-info btn-custom" data-toggle="modal"  onClick={() => setShowModal(true)}>Watch Video</button>
                        <button type="button" className="btn btn-info btn-custom" onClick={() => navigate('/cost-structure-help')}>Cost Structure Help</button>
                    </div>
                </div>
                <div className="col-md-8 right-section">
                {error && <Alert severity="error" onClose={() => setError('')}>{error}</Alert>}
            {success && <Alert severity="success" onClose={() => setSuccess('')}>{success}</Alert>}
                    <form id="costStructureForm" className="form-main">
                        <div className="form-group d-flex flex-column">
                            <textarea 
                             value={costStructure}
                             onChange={(e) => setCostStructure(e.target.value)}
                            className="form-control" id="costStructure" name="costStructure" rows="15" required></textarea>
                            <button type="submit" onClick={handleSubmit} className="btn btn-info btn-primary-custom">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    {showModal && <VideoModal videoUrl="https://www.youtube.com/embed/ltaMbQ5O7ys?autoplay=1" onClose={() => setShowModal(false)} title="Cost Structure Video" />}
    </div>
    </>
    );
};

export default CostStructureHelp;
