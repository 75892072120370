import { FunctionComponent } from "react";

const Error: FunctionComponent = () => {
    return(
        <>
            <h1>404 Error Page</h1>
            <p>Sorry, This page doesn't exist</p>
            <a href="/">GO Back</a>
        </>
    )
}
export default Error;