import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { Button, Alert, CircularProgress } from '@mui/material';
import { Container, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography, CircularProgress, Alert } from '@mui/material';

import styles from '../styles/KeyActivitiesPage.module.css';
import { config } from "../config/config";
// import Storage from '../utilities/Storage';
import Storage from '../utilities/Storage';

const Dashboard = () => {
    const token = Storage.getItem('accesstoken');
    const navigate = useNavigate();
    const [dashboardData, setDashboardData] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true); // Loader state
    const [allComplete, setAllComplete] = useState(true);
    const [downloadLoading, setDownloadLoading] = useState(false);

    useEffect(() => {
        // if (token == null) {
        //     setToken(Storage.getItem('accesstoken'))
        // }
        if (Storage.getItem('accesstoken') == null) {
            navigate('/login');
        } 

        const fetchDashboardData = async () => {
            try {
                const response = await fetch(config.apiUrl+'/user/get_dashboard_data', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'locale': 'en',
                        'tokentype': 'email',
                        'accesstoken': token,
                       
                      },
                });

                const data = await response.json();

                if (response.status === 200) {
                    setDashboardData(data);
                    let completeStatus = true;

                    data.forEach(item => {
                        if (item.status !== 'Complete') {
                            completeStatus = false;
                        }
                    });

                    setAllComplete(completeStatus);
                } else {
                    throw new Error(data.message || 'Failed to load dashboard data');
                }
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchDashboardData();
    }, []);

    const handleDownloadBMC = async () => {
      setDownloadLoading(true);
        try {
            const response = await fetch(config.apiUrl+'/user/download_bmc', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'locale': 'en',
                    'tokentype': 'email',
                    'accesstoken': token,
                   
                  },
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'BusinessModelCanvas.docx';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                throw new Error('Failed to download Business Model Canvas');
            }
        } catch (error) {
            console.error('Error downloading Business Model Canvas:', error);
            setError(error.message);
        } finally {
          setDownloadLoading(false);
        }
    };

    const handleValidateBusinessModel = async () => {
        try {
            const response = await fetch(config.apiUrl+'/user/validate_business_model', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'locale': 'en',
                    'tokentype': 'email',
                    'accesstoken': token,
                   
                  },
            });

            const data = await response.json();

            if (response.status === 200) {
                const parsedData = JSON.parse(data.result.replace(/```json\n|\n```/g, ''));
                const result = JSON.stringify(parsedData, null, 2);
                displayPopup(result);
            } else {
                throw new Error(data.message || 'Failed to validate business model');
            }
        } catch (error) {
            console.error('Error validating business model:', error);
            setError(error.message);
        }
    };

    const displayPopup = (result) => {
        const popup = document.createElement('div');
        popup.classList.add('popup-overlay');
        popup.innerHTML = `
            <div class="popup-content">
                <h3>Business Model Validation</h3>
                <pre class="json-display"><code>${escapeHTML(result)}</code></pre>
                <button id="closePopup" class="btn btn-primary">OK</button>
            </div>
        `;
        document.body.appendChild(popup);

        document.getElementById('closePopup').addEventListener('click', () => {
            document.body.removeChild(popup);
        });
    };

    const escapeHTML = (string) => {
        const pre = document.createElement('pre');
        const text = document.createTextNode(string);
        pre.appendChild(text);
        return pre.innerHTML;
    };

    const renderTableRows = (section, steps) => {
        return dashboardData
            .filter(item => steps.includes(item.stepName))
            .map((item, index) => (
                <tr key={index}>
                    {/* <td>
                        <a  onClick={ navigate(item.stepName) } >
                            {item.stepName.replace('.html', '').replace(/([A-Z])/g, ' $1')}
                        </a>
                    </td> */}
                    <td>
                        { item.stepName=="customerSegments" && 
                            (
                        <a href="/customer-segments">
                        {item.stepName.replace('.html', '').replace(/([A-Z])/g, ' $1')}
                        </a>
                        )
                        }

                        { item.stepName=="valuePropositions" && 
                            (
                        <a href="/value-propositions">
                        {item.stepName.replace('.html', '').replace(/([A-Z])/g, ' $1')}
                        </a>
                        )
                        }

{ item.stepName=="channels" && 
                            (
                        <a href="/channel-resources">
                        {item.stepName.replace('.html', '').replace(/([A-Z])/g, ' $1')}
                        </a>
                        )
                        }

{ item.stepName=="customerRelationships" && 
                            (
                        <a href="/customer-relationships">
                        {item.stepName.replace('.html', '').replace(/([A-Z])/g, ' $1')}
                        </a>
                        )
                        }

{ item.stepName=="revenueStreams" && 
                            (
                        <a href="/revenue-streams">
                        {item.stepName.replace('.html', '').replace(/([A-Z])/g, ' $1')}
                        </a>
                        )
                        }

{ item.stepName=="keyActivities" && 
                            (
                        <a href="/key-activities">
                        {item.stepName.replace('.html', '').replace(/([A-Z])/g, ' $1')}
                        </a>
                        )
                        }

{ item.stepName=="keyResources" && 
                            (
                        <a href="/key-resources">
                        {item.stepName.replace('.html', '').replace(/([A-Z])/g, ' $1')}
                        </a>
                        )
                        }

{ item.stepName=="keyPartners" && 
                            (
                        <a href="/key-partners">
                        {item.stepName.replace('.html', '').replace(/([A-Z])/g, ' $1')}
                        </a>
                        )
                        }

{ item.stepName=="costStructure" && 
                            (
                        <a href="/cost-structure">
                        {item.stepName.replace('.html', '').replace(/([A-Z])/g, ' $1')}
                        </a>
                        )
                        }


                       
                    </td>
                    <td><span className="duration">{item.duration}</span></td>
                    <td>
                        <a href={item.studyMaterial} target="_blank" rel="noopener noreferrer">
                            Study Material
                        </a>
                    </td>
                    <td>
                        {item.status === 'Complete' ? (
                            <span className="completed">Complete</span>
                        ) : (
                            <span className="incomplete">Incomplete</span>
                        )}
                    </td>
                </tr>
            ));
    };

    const desirabilitySteps = ["valuePropositions", "customerSegments", "channels", "customerRelationships"];
    const feasibilitySteps = ["keyActivities", "keyResources", "keyPartners"];
    const viabilitySteps = ["revenueStreams", "costStructure"];

    return (
        
        <div className='dashboard_page'>
            <h2>Dashboard</h2>
            {error && <Alert severity="error" onClose={() => setError('')}>{error}</Alert>}

            {loading ? (
                <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
            ) : (
                <>
                    <table id="dashboardTable">
                        <tbody>
                            <tr className="section-header" data-section="desirability">
                                <td colSpan="4">Desirability</td>
                            </tr>
                            {renderTableRows('desirability', desirabilitySteps)}
                            <tr className="section-header" data-section="feasibility">
                                <td colSpan="4">Feasibility</td>
                            </tr>
                            {renderTableRows('feasibility', feasibilitySteps)}
                            <tr className="section-header" data-section="viability">
                                <td colSpan="4">Viability</td>
                            </tr>
                            {renderTableRows('viability', viabilitySteps)}
                        </tbody>
                    </table>


                    

<Button
        variant="contained"
        color="primary"
        onClick={handleValidateBusinessModel}
        disabled={!allComplete}
        style={{ marginTop: '20px' }}
      >
        Validate Business Model
      </Button>

      <Button
      className="btn btn-primary"
        variant="contained"
        color="secondary"
        onClick={handleDownloadBMC}
        style={{ marginTop: '20px', marginLeft: '20px',backgroundColor:'#5A97A4' }}
        disabled={downloadLoading}
      >
        {downloadLoading ? <CircularProgress size={24} /> : 'Download BMC'}
      </Button>
                </>
            )}
        </div>
    );
};

export default Dashboard;
