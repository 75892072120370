import React, { useState,useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { config } from "../config/config";
// import Storage from '../utilities/Storage';
import Storage from '../utilities/Storage';

import {
    Button,
    Alert,
    CircularProgress,
  } from '@mui/material';

const Login= () => {
    // const { login,token } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
      setError('');
     
      try {
        const payload = {
          req_param: {
            email_phone: email,
            password: password
          }
        };
        const response = await fetch(config.apiUrl+'/user/login', {
          method: 'POST',
          // mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json',
            'locale': 'en',
            // 'tokentype': 'email',
            // 'accesstoken': '••••••',
            // 'user-id': '83ad4ef9-1a31-4731-bdd7-fb930a06532e',
          },
          // credentials: 'include',
          body: JSON.stringify(payload),
        });
  
        const data = await response.json();
       // console.log(data);
        //return false;
  
        if (response.status === 200) {
         // window.location.href = data.redirect;
        //  const userToken = 'exampleToken123';
         // Call the login method from AuthContext with the received token
        //  login(userToken); //set the token
        // login(data.res_data.data.accesstoken); //set the token
        Storage.setItem("accesstoken", data.res_data.data.accesstoken)
        //  alert(data.redirect)
         if(data.redirect=='businessIdea.html'){
          navigate('/business-idea');
         }else{
          //navigate('/dashboard');
          navigate('/business-idea');
         }

        } else {
          setError(data.error.message);
          // throw new Error(data.message);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    return (

<>
<div className="keyActivitiesPage">
<div  className="container">
        <div  className="card_black">
            <div  className="row justify-content-center">
                <div  className="col-md-6">
                    <h2  className="text-center mt-5">Login</h2>
                    {error && <Alert severity="error">{error}</Alert>}
     
                    <form id="loginForm"  className="mt-4 form-main" onSubmit={handleSubmit}>
                        <div  className="form-group d-flex flex-column">
                            <label for="email">Email:</label>
                            <input
                             value={email}
                             onChange={(e) => setEmail(e.target.value)}
                            type="email"  className="form-control" id="email" name="email" required/>
                        </div>
                        <div  className="form-group d-flex flex-column">
                            <label for="password">Password:</label>
                            <input 
                             value={password}
                             onChange={(e) => setPassword(e.target.value)}
                            type="password"  className="form-control" id="password" name="password" required/>
                        </div>
                        {/* <button type="submit"  className="btn btn-primary mt-4 ml-auto">Login</button> */}
                        <Button
                       className="btn btn-custom mt-4 ml-auto"
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          style={{ marginTop: '16px' }}
        >
          {loading ? <CircularProgress size={24} /> : 'Login'}
        </Button>
                    </form>
                    <p  className="text-center mt-3">
                        Don't have an account? <a href="/register">Register</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    </div>
    </>
    );
};

export default Login;
