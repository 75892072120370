import React, { useState,useEffect,useContext } from 'react';
import { TextField, Button, CircularProgress, Container, Typography, Box, Alert } from '@mui/material';
import { config } from "../config/config";
import Storage from '../utilities/Storage';

const DebuggingPage = () => {
     const token = Storage.getItem('accesstoken');
    const [allUsers, setAllUsers] = useState('');
    const [userId, setUserId] = useState('');
    const [userBusinessPlan, setUserBusinessPlan] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
 // Fetch all users when the component mounts
 useEffect(() => {
    const fetchAllUsers = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await fetch(config.apiUrl+'/user/get_all_users', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'locale': 'en',
                    'tokentype': 'email',
                    'accesstoken': token,
                   
                  },
            });
            console.log(response);
            if (response.ok) {
                const data = await response.json();
                setAllUsers(JSON.stringify(data, null, 2));
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to load users');
            }
        } catch (error) {
            console.error('Error fetching users:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
    fetchAllUsers();
}, []); // Empty dependency array ensures this runs once on component mount

    const fetchUserBusinessPlan = async () => {
        if (!userId.trim()) {
            setError('Please enter a User ID.');
            return;
        }

        setLoading(true);
        setError('');
        try {
            const response = await fetch(config.apiUrl+`/user/get_user_business_plan_steps/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'locale': 'en',
                    'tokentype': 'email',
                    'accesstoken': token,
                   
                  },
            });

            if (response.ok) {
                const data = await response.json();
                setUserBusinessPlan(JSON.stringify(data, null, 2));
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to load user business plan steps');
            }
        } catch (error) {
            console.error('Error fetching user business plan steps:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container sx={{ mt: 5 }}>
            <Typography variant="h4" sx={{ color: '#FFFFFF' }}>Debugging Page</Typography>
            <Box mt={3}>
                <TextField
                    label="All Users"
                    value={allUsers}
                    multiline
                    rows={5}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        readOnly: true,
                        style: { color: '#ffffff' },
                    }}
                    InputLabelProps={{
                        style: { color: '#90a4ae' },
                    }}
                    
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#37474f',
                            },
                            '&:hover fieldset': {
                                borderColor: '#607d8b',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#90a4ae',
                            },
                        },
                    }}
                />
            </Box>
            <Box mt={3}>
                <TextField
                    label="Enter User ID"
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        style: { color: '#ffffff' },
                    }}
                    InputLabelProps={{
                        style: { color: '#90a4ae' },
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#37474f',
                            },
                            '&:hover fieldset': {
                                borderColor: '#607d8b',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#90a4ae',
                            },
                        },
                    }}
                />
            </Box>
            <Box mt={3}>
                <TextField
                    label="User Business Plan Steps"
                    value={userBusinessPlan}
                    multiline
                    rows={10}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        readOnly: true,
                        style: { color: '#ffffff' },
                    }}
                    InputLabelProps={{
                        style: { color: '#90a4ae' },
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#37474f',
                            },
                            '&:hover fieldset': {
                                borderColor: '#607d8b',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#90a4ae',
                            },
                        },
                    }}
                />
            </Box>
            {loading ? (
                <Box mt={3} textAlign="center">
                    <CircularProgress />
                </Box>
            ) : (
                <Box mt={3}>
                    <Button variant="contained" color="primary" onClick={fetchUserBusinessPlan}>
                        Fetch User Business Plan Steps
                    </Button>
                </Box>
            )}
            {error && (
                <Box mt={3}>
                    <Alert severity="error" onClose={() => setError('')}>{error}</Alert>
                </Box>
            )}
        </Container>
    );
};

export default DebuggingPage;
