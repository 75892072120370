let ENV = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  CLARITY_PROJECT_ID: process.env.REACT_CLARITY_PROJECT_ID,
  REACT_SENTRY_DSN: process.env.REACT_SENTRY_DSN,
  REACT_DOMAIN_NAME: process.env.REACT_DOMAIN_NAME,
  basePath: process.env.REACT_APP_BASEPATH,
  contactTo: process.env.REACT_APP_CONTACT_TO,
};

export const config = { env: ENV, ...{
  apiUrl:ENV.apiBaseUrl,
  CLARITY_PROJECT_ID:ENV.CLARITY_PROJECT_ID,
  REACT_SENTRY_DSN:ENV.REACT_SENTRY_DSN,
  REACT_DOMAIN_NAME:ENV.REACT_DOMAIN_NAME,
  loginUrl: ENV.loginUrl,
  lng: "en",
  version:"1.0",
  globalVar : {
    loaderColor: '#00A1DE',
  }
}
};

