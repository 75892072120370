import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import VideoModal from '../../components/VideoModal';
import styles from '../../styles/KeyActivitiesPage.module.css';
import { Alert } from '@mui/material';
import { config } from "../../config/config";
const CustomerSegmentsHelp= () => {
    const [customerSegments, setCustomerSegments] = useState('');
    const [caseStudies, setCaseStudies] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    // Load customer segments from localStorage on component mount
    useEffect(() => {
        const savedCustomerSegments = localStorage.getItem('customerSegments');
        setCustomerSegments(savedCustomerSegments || '');
    }, []);

    // Handle form submission to save customer segments
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(config.apiUrl+'/save_customer_segments', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ customer_segments: customerSegments })
            });

            const data = await response.json();

            if (response.status === 200) {
                window.location.href = 'valuePropositions.html';
            } else {
                throw new Error(data.message || 'Unknown error saving customer segments');
            }
        } catch (error) {
            console.error('Error saving customer segments:', error);
            setError(error.message);
        }
    };

    // Handle case studies generation
    const handleGenerateCaseStudies = async () => {
        if (!customerSegments.trim()) {
            setError('Please enter customer segments before generating case studies.');
            return;
        }

        try {
            const response = await fetch(config.apiUrl+'/generate_case_studies', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ type: 'customer_segments', content: customerSegments })
            });

            const data = await response.json();

            if (response.status === 200) {
                setCaseStudies(data.caseStudies);
                setSuccess('Case studies generated successfully');
            } else {
                throw new Error('Error generating case studies');
            }
        } catch (error) {
            console.error('Error generating case studies:', error);
            setError(error.message);
        }
    };


    return (

<>
<div className="keyActivitiesPage">
        <div className="container">
        <div className="card_black">
            <div className="row">
                <div className="col-md-4 left-section">
                <h2 class="text-left">Customer Segments</h2>
                    <p class="text-white description">Customer Segments describe the type of customers you will prioritize. Please review and edit the pre-filled suggestions below to best match your business idea.</p>
                    <div className="buttons-wrapper">
                        <button type="button" className="btn btn-info btn-custom" data-toggle="modal"  onClick={() => setShowModal(true)}>Watch Video</button>
                        <button type="button" className="btn btn-info btn-custom" onClick={() => navigate('/customer-segments-help')}>Customer Segment Help</button>
                    </div>
                </div>
                <div className="col-md-8 right-section">
                {error && <Alert severity="error" onClose={() => setError('')}>{error}</Alert>}
            {success && <Alert severity="success" onClose={() => setSuccess('')}>{success}</Alert>}
                    <form id="CustomerSegForm" className="form-main">
                        <div className="form-group d-flex flex-column">
                            <textarea 
                             value={customerSegments}
                             onChange={(e) => setCustomerSegments(e.target.value)}
                            className="form-control" id="CustomerSeg" name="CustomerSeg" rows="15" required></textarea>
                            <button type="submit" onClick={handleSubmit} className="btn btn-info btn-primary-custom">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    {showModal && <VideoModal videoUrl="https://www.youtube.com/embed/qTQEooaSNrs?autoplay=1" onClose={() => setShowModal(false)} title="Customer Segments Video" />}
    </div>
    </>
    );
};

export default CustomerSegmentsHelp;
